import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"
import { ExternalButtonLink } from "components/buttons"

const StyledHighPraise = styled.section`
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	margin: 0 auto;
	padding: 60px 0px 40px;
	border-bottom-width: 0;
	background: ${colors.white};
	max-width: 1000px;

	${breakpoint.medium`
		padding: 120px 0px 100px;
	`}

  	.header {
		text-align: center;
		margin: 0px auto;
	}

	h2 {
		margin-bottom: 20px;
		text-align: center;

		${breakpoint.medium`
			margin-bottom: 40px;
		`}

	}
	
	h3 {
		text-align: center;
		margin-bottom: 10px;
		line-height: 1.4em;
		
		${breakpoint.medium`
			margin-bottom: 30px;
		`}
	
	}
	
	p { 
		text-align: center;
		margin-bottom: 10px;
		
		${breakpoint.medium`
			margin-bottom: 30px;
		`}
	}

  .bullets {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0px;

	${breakpoint.medium`
	margin: 1rem 0px 1rem;
	`}

	.bullet {
		  width: 13px;
		  height: 13px;
		  position: relative;
		  display: flex;
		  margin: 20px 3px 0px 4px;
		  border-radius: 50%;
		  transition: all 0.3s;
		  cursor: pointer;

	  &::before {
		content: "";
		width: 7px;
		height: 7px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		border: 1px solid ${colors.orange};
		border-radius: 50%;
	  }

	  &:hover,
	  &.glide__bullet--active {
		background-color: ${colors.orange};
	  }

	  &:last-child {
		margin-right: 0;
	  }
	}
  }

	.break__sections {
		display: flex;
		flex-wrap: wrap;

		${breakpoint.small`
			flex-wrap: nowrap;
		`}
	
	}

    ${ButtonLink} {
      width: auto;
	}

    ${ExternalButtonLink} {
      width: auto;
	}

	.glide__slides { 
		transform:none !important; 
		width:auto !important;
		display: grid; 
		grid-template-areas: 'slide';  
	}
	.glide__slide {
		position: relative; 
		opacity: 0; 
		transition: opacity 500ms ease; 
		grid-area: slide;  
	}
	.glide__slide--active {
		 z-index:1; 
		 opacity:1;
	}

`

const StyledBreakSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
	tabindex: -1;

  .break__content {
  		width: 80%;
		text-align: center;
	}

	h3 {
		font-weight: 300;
		margin-bottom: 10px;
	}
 	 	
	.praise__icon {
		height: 100%;
		max-height: 120px;
		margin: 20px auto;
	}
  
`

class Carousel extends React.Component {
	constructor(props) {
		super(props)
		this.myRef = React.createRef()
	}

	componentDidMount = () => {
		const carousel = new Glide(this.myRef.current, {
			autoplay: 6000,
			animationDuration: 0,
			animationTimingFunc: 'ease-in',
			type: "carousel",
		})
		carousel.mount()
	}

	render = props => (
		<div className="glide" ref={this.myRef}>
			<div className="glide__track" data-glide-el="track">
				<div className="glide__slides">{this.props.children}</div>
				<div className="bullets glide__bullets" data-glide-el="controls[nav]">
					<button type="bullet" aria-label="First slide" className="bullet" data-glide-dir="=0"></button>
					<button type="bullet" aria-label="Second slide" className="bullet" data-glide-dir="=1"></button>
					<button type="bullet" aria-label="Third slide" className="bullet" data-glide-dir="=2"></button>
					<button type="bullet" aria-label="Fourth slide" className="bullet" data-glide-dir="=3"></button>
				</div>
			</div>
		</div>
	)
}

const HighPraise = () => {
	return (
		<StyledHighPraise>
			<Container data-aos="fade-up" data-aos-once="false">
				<div className="header">
					<h2>Recognition:</h2>
					<h3>To the agencies and entities that supported us, we are proud to deliver the next generation of PCR diagnostics.</h3>
					<p>At Visby Medical, we humbly believe our new diagnostic platform will become the gold standard among PCR point-of-care devices,
						by delivering accurate results in under 30 minutes to empower clinicians with data-driven results, while with their patient.</p>
				</div>
				<Carousel>
					<div className="glide__slide">
						<div className="break__sections">
							<StyledBreakSection>
								<div className="break__content">
									<StaticImage
										src="../../../assets/images/homepage/high-praise/barda-logo.png"
										width={120}
										alt="BARDA logo"
										className="praise__icon"
									/>
									<p><b>February 2, 2021:</b> Visby receives a $12.3 million award from BARDA to speed development of its rapid, single-use Flu-COVID PCR Test.</p>
									<ButtonLink
										backgroundcolor="orange"
										to="/news/press-release-visby-medical-speeds-development-of-a-rapid-flu-covid-pcr-test-designed-for-at-home-use-with-barda-funding/"
										aria-label="Read more about Visby receiving a $12.3 million award from BARDA"
										tabIndex="-1"
									>
										Read more
									</ButtonLink>
								</div>
							</StyledBreakSection>
						</div>
					</div>
					<div className="glide__slide">
						<div className="break__sections">
							<StyledBreakSection>
								<div className="break__content">
									<StaticImage
										src="../../../assets/images/homepage/high-praise/nih-lancet-logo.png"
										height={80}
										layout="fixed"
										alt="NIH and Lancet logos"
										className="praise__icon"
									/>
									<p><b>November 23, 2020:</b> Visby Medical Sexual Health Test clinical study, sponsored by NIH/NIAID, is published in The Lancet: “Performance of a single-use, rapid, point-of-care PCR device for the detection
										of <i>Neisseria gonorrhoeae</i>, <i>Chlamydia trachomatis</i>, and <i>Trichomonas vaginalis</i>: a cross-sectional study.”</p>
									<ExternalButtonLink
										backgroundcolor="orange"
										href="https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(20)30734-9/fulltext"
										target="_blank"
										rel="noopener noreferrer"
										aria-label="Read more about Visby Medical Sexual Health Test clinical study"
										tabIndex="-1"
									>
										Read more
									</ExternalButtonLink>
								</div>
							</StyledBreakSection>
						</div>
					</div>
					<div className="glide__slide">
						<div className="break__sections">
							<StyledBreakSection>
								<div className="break__content">
									<StaticImage
										src="../../../assets/images/homepage/high-praise/nih-logo.png"
										height={80}
										layout="fixed"
										alt="NIH logo"
										className="praise__icon"
									/>
									<p><b>October 6, 2020:</b> Visby receives $10 Million from the NIH as part of its Rapid Acceleration of Diagnostics (RADx) initiative.</p>
									<ButtonLink
										backgroundcolor="orange"
										to="/news/visby-medicals-personal-pcr-receives-funding-from-national-institute-of-health-accepted-into-radx-program/"
										aria-label="Read more about Visby receiving $10 Million from the NIH"
										tabIndex="-1"
									>
										Read more
									</ButtonLink>
								</div>
							</StyledBreakSection>
						</div>
					</div>
					<div className="glide__slide">
						<div className="break__sections">
							<StyledBreakSection>
								<div className="break__content">
									<StaticImage
										src="../../../assets/images/homepage/high-praise/nih-barda-logo.png"
										width={200}
										alt="NIH and BARDA logos"
										className="praise__icon"
									/>
									<p><b>August 5, 2020:</b> Visby Medical selected as the ONLY winner of $19 Million in the Antimicrobial Resistance (AMR) Diagnostic Challenge (co-sponsored by BARDA and the NIH).</p>
									<ButtonLink
										backgroundcolor="orange"
										to="/news/press-release-rapid-diagnostic-for-gonorrhea-wins-19-million-federal-prize-competition-to-combat-antibiotic-resistance/"
										aria-label="Read more about Visby Medical winning $19 Million in the Antimicrobial Resistance Diagnostic Challenge"
										tabIndex="-1"
									>
										Read more
									</ButtonLink>
								</div>
							</StyledBreakSection>
						</div>
					</div>
				</Carousel>
			</Container>
		</StyledHighPraise>
	)
}

export default HighPraise
